<template>
	<div>
		<div class="top-title">
			<van-icon name="arrow-left" class="icon-fan" @click="fan()" />
			<span>{{title_span}}</span>
		</div>

		<div class="alls">
			<van-row class="van-row">
				<van-col span="9" class="text-left">姓名</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userName" placeholder="请输入姓名" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">性别</van-col>
				<van-col span="15" class="text-right" @click="selectSex()">{{sexEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">身份证号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userNumber" placeholder="请输入身份证号" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">证书类型</van-col>
				<van-col span="15" class="text-right" @click="selectUserCardType()">{{userCardTypeEn}}</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType!=null">
				<van-col span="9" class="text-left">
					<span v-if="userCardType==0">健康证编号</span>
					<span v-if="userCardType==1">药师资格证编号</span>
					<span v-if="userCardType==2">项目代号</span>
				</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userCardNumber" placeholder="请输入" />
				</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==0||userCardType==1">
				<van-col span="9" class="text-left">有效日期</van-col>
				<van-col span="15" class="text-right" @click="selectEffectiveDate()">{{effectiveDateEn}}</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==2">
				<van-col span="9" class="text-left">有效日期</van-col>
				<van-col span="15" class="text-right">
					<van-row class="van-row">
						<van-col span="10" @click="selectEffectiveDate1()" class="text-right1">{{effectiveDate1En}}</van-col>
						<van-col style="4" class="text-right1">
							<div style="text-align: center;">—</div>
						</van-col>
						<van-col span="10" @click="selectEffectiveDate()" class="text-right1">{{effectiveDateEn}}</van-col>
					</van-row>
				</van-col>
			</van-row>

			<van-row class="van-row-img" v-if="userCardType!=null">
				<van-col span="9" class="text-left">
					<span v-if="userCardType==0">健康证照片</span>
					<span v-if="userCardType==1">证件照片</span>
					<span v-if="userCardType==2">证件照片</span>
				</van-col>
				<van-col span="15" class="text-right">
					<van-uploader v-model="yyImgList" :before-read="addYyImg" :max-count="1" preview-full-image :preview-options="{closeable: true}" @delete="yyImg"/>
				</van-col>
			</van-row>

			<!-- <van-row class="van-row" v-if="userCardType==0">
				<van-col span="9" class="text-left">体检编号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userCardInspect" placeholder="请输入体检编号" />
				</van-col>
			</van-row>
			
			<van-row class="van-row" v-if="userCardType==0">
				<van-col span="9" class="text-left">体检单位</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userCardCompany" placeholder="请输入体检单位" />
				</van-col>
			</van-row>-->

			<van-row class="van-row" v-if="userCardType==1">
				<van-col span="9" class="text-left">注册日期</van-col>
				<van-col span="15" class="text-right" @click="selectEffectiveDate1()">{{userRegisterEn}}</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==1">
				<van-col span="9" class="text-left">注册证编号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userRegisterNumber" placeholder="请输入注册证编号" />
				</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==1">
				<van-col span="9" class="text-left">执业地区</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userCardAddress" placeholder="请输入执业地区" />
				</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==1||userCardType==2">
				<van-col span="9" class="text-left">
					<span v-if="userCardType==1">执业类型</span>
					<span v-if="userCardType==2">特设证书类型</span>
				</van-col>
				<van-col span="15" class="text-right" @click="selectUserRelation">{{userRelationEn}}</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==1">
				<van-col span="9" class="text-left">执业范围</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userCardRange" placeholder="请输入执业范围" />
				</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==2">
				<van-col span="9" class="text-left">发证机关</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="userCardOffice" placeholder="请输入发证机关" />
				</van-col>
			</van-row>

			<van-row class="van-row" v-if="userCardType==1||userCardType==2">
				<van-col span="9" class="text-left">
					<span v-if="userCardType==1">执业单位</span>
					<span v-if="userCardType==2">所属单位</span>
				</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseName" readonly />
				</van-col>
			</van-row>

			<div class="submit-type">
				<van-button block type="info" color="#1989FA" @click="adduser()" :loading="loading" :disabled="loading" class="submit-button">提交</van-button>
			</div>
		</div>

		<van-popup v-model:show="sexShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="性别" show-toolbar :default-index="defaultIndex" :columns="sexList" @confirm="sexConfirm" @cancel="sexCancel" />
		</van-popup>

		<van-popup v-model:show="userCardTypeShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="证书类型" show-toolbar :default-index="defaultIndex" :columns="userCardTypeList" @confirm="userCardTypeConfirm" @cancel="userCardTypeCancel" />
		</van-popup>

		<van-popup v-model:show="effectiveDateShow" round position="bottom" :style="{ width: '100%' }">
			<van-datetime-picker v-model="currentDate" type="date" :title="dateTitle" :min-date="minDate" :max-date="maxDate" @confirm="dateConfirm" @cancel="dateCancel" />
		</van-popup>

		<van-popup v-model:show="effectiveDate1Show" round position="bottom" :style="{ width: '100%' }">
			<van-datetime-picker v-model="currentDate1" type="date" :title="dateTitle1" :min-date="minDate1" :max-date="maxDate1" @confirm="dateConfirm1" @cancel="dateCancel1" />
		</van-popup>

		<van-popup v-model:show="userRelationShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="执业类型" show-toolbar :default-index="defaultIndex" :columns="userRelationList" @confirm="userRelationConfirm" @cancel="userRelationCancel" />
		</van-popup>
	</div>
</template>
<style scoped>
	.alls {
		padding-bottom: 2rem;
	}

	.top-title {
		background-color: #025193;
		color: white;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
		z-index: 999;
		text-align: center;
		font-size: 0.49rem;
		letter-spacing: 0.026667rem;
	}

	.icon-fan {
		position: absolute;
		left: 0.133333rem;
		top: 0.24rem;
		font-size: 0.746667rem;
	}

	.alls {
		padding-top: 1.173333rem;
		width: 100%;
	}

	.van-row {
		height: 1.066667rem;
		line-height: 1.066667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.text-left {
		font-size: 0.373333rem;
		padding: 0px 0.133333rem;
	}

	.text-right {
		font-size: 0.373333rem;
		padding: 0px 0.133333rem;
	}

	.van-cell {
		padding: 0.15rem 0.133333rem;
	}

	.van-row-img {
		height: 3.466667rem;
		line-height: 3.466667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.van-uploader {
		margin-top: 0.266667rem;
	}

	.text-right1 {
		font-size: 0.373333rem;
		padding: 0px;
		text-align: center;
	}

	.submit-type {
		width: 100%;
		height: 1.45rem;
		padding-top: 0.15rem;
		background-color: white;
		position: fixed;
		bottom: 0px;
		left: 0rem;
	}

	.submit-button {
		width: 90%;
		margin-left: 5%;
		height: 1.2rem;
		border-radius: 0.16rem;
	}
</style>
<script>
	import EnterpriseAdd from "@/js/add/PersonnelAdd.js";
	export default {
		...EnterpriseAdd,
	};
</script>
