import { getCurrentInstance, toRefs, ref, reactive } from "vue";
import { useRouter } from 'vue-router'
import { baseUrlImg } from '@/utils/common'
import { dateToStrCopy } from '@/utils/date'
import Compressor from 'compressorjs'
// import { ImagePreview } from 'vant'

export default {
	setup(props, context) {
		document.title = "从业人员操作";

		const { proxy } = getCurrentInstance();

		const router = useRouter();

		const dataAll = reactive({
			userData: {},
			baseUrlImg: baseUrlImg(),
			urlImg: ref(""),
			sexShow: ref(false),
			sexList: ref([]),
			sexEn: ref("请选择性别"),
			effectiveDateShow: ref(false),
			effectiveDateEn: ref("有效日期"),
			effectiveDate: ref(null),
			currentDate: new Date(),
			dateTitle: "请选择时间",
			minDate: new Date(2010, 0, 1),
			maxDate: new Date(2110, 11, 31),
			yyImgList: ref([]),
			enterpriseLicense: ref(null),
			userCardTypeShow: ref(null),
			userCardTypeList: ref(null),
			userCardTypeEn: ref("请选择证书类型"),
			userRegisterEn: ref("请选择注册日期"),
			userRegisterShow: ref(false),
			userRegisterDate: new Date(),
			dateUserRegisterTitle: "请选择时间",
			minUserRegisterDate: new Date(2010, 0, 1),
			maxUserRegisterDate: new Date(2110, 11, 31),
			userRelationEn: ref("请选择执业类型"),
			userRelationShow: ref(false),
			userRelationList: ref(null),

			effectiveDate1Show: ref(false),
			effectiveDate1En: ref("有效日期"),
			effectiveDate1: ref(null),
			currentDate1: new Date(),
			dateTitle1: "请选择时间",
			minDate1: new Date(2010, 0, 1),
			maxDate1: new Date(2110, 11, 31),
			title_span: ref(null),

			userId: proxy.$router.currentRoute.value.params.userId,
			userSex: ref(null),
			userCardType: ref(null),
			userNumber: ref(null),
			userName: ref(null),
			userCardNumber: ref(null),
			userCardSrc: ref(null),
			userCardInspect: ref(null),
			userCardCompany: ref(null),
			userRegisterTime: ref(null),
			userRegisterNumber: ref(null),
			userCardAddress: ref(null),
			userRelation: ref(null),
			userCardRange: ref(null),
			enterpriseQrNumber: sessionStorage.getItem('enterpriseQrNumber'),
			enterpriseName: sessionStorage.getItem('enterpriseName'),
			userCardEtime: ref(null),
			userCardStime: ref(null),
			userCardOffice: ref(null),
			enterpriseQrNumber: sessionStorage.getItem("enterpriseQrNumber"),
			defaultIndex: ref(null),
			loading: ref(false)
		});

		if (dataAll.userId) {
			document.title = "修改人员";
			dataAll.title_span = "修改人员";
			
			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/getUser`),
				method: 'post',
				data: {
					userId: dataAll.userId
				},
				headers: {
					token: sessionStorage.getItem("userToken")
				},
			}).then(({
				data
			}) => {
				console.log(data.data);
				if (data && data.code === 0) {
					if (data.data != null) {
						Object.assign(dataAll, data.data);
						if (dataAll.userCardSrc != null && dataAll.userCardSrc != '') {
							dataAll.yyImgList.push({ url: baseUrlImg() + dataAll.userCardSrc });
						}
						dataAll.sexEn = dataAll.userSex == 0 ? "女" : "男";
						if (dataAll.userCardType == 0) {
							dataAll.effectiveDateEn = dataAll.userCardEtime;
							dataAll.currentDate = new Date(dataAll.userCardEtime);
						} else if (dataAll.userCardType == 1) {
							dataAll.effectiveDateEn = dataAll.userCardEtime;
							dataAll.currentDate = new Date(dataAll.userCardEtime);
							dataAll.userRegisterEn = dataAll.userCardStime;
							dataAll.currentDate1 = new Date(dataAll.userCardStime);
							dataAll.userRelationEn = data.data.pharmacistName;
						} else {
							dataAll.effectiveDate1En = dataAll.userCardStime;
							dataAll.currentDate1 = new Date(dataAll.userCardStime);
							dataAll.effectiveDateEn = dataAll.userCardEtime;
							dataAll.currentDate = new Date(dataAll.userCardEtime);
							dataAll.userRelationEn = data.data.facilityName;
						}
					} else {
						proxy.$toast.fail("参数缺失！");
					}
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		} else {
			document.title = "新增人员";
			dataAll.title_span = "新增人员";
		}

		const fan = () => { router.go(-1); }

		// 性别下拉选择
		const selectSex = () => {
			dataAll.sexList = [
				{ text: "男", id: 1 }, 
				{ text: "女", id: 0 },
			];
			dataAll.defaultIndex = dataAll.sexList.findIndex((ace) => ace.id == dataAll.userSex);
			dataAll.sexShow = true;
		}
		const sexConfirm = (value) => {
			dataAll.sexEn = value.text;
			dataAll.userSex = value.id;
			dataAll.sexShow = false;
		}
		const sexCancel = () => {
			dataAll.sexShow = false;
		}
		
		// 有效日期 起 选择
		const selectEffectiveDate1 = () => {
			dataAll.effectiveDate1Show = true
		}
		const dateConfirm1 = (value) => {
			dataAll.userRegisterEn = dateToStrCopy(new Date(value));
			dataAll.effectiveDate1En = dateToStrCopy(new Date(value));
			dataAll.currentDate1 = dateToStrCopy(new Date(value));
			dataAll.userCardStime = dateToStrCopy(new Date(value));
			dataAll.effectiveDate1Show = false;
		}
		const dateCancel1 = () => {
			dataAll.effectiveDate1Show = false;
		}
		
		// 有效日期 止 选择 
		const selectEffectiveDate = () => {
			dataAll.effectiveDateShow = true
		}
		const dateConfirm = (value) => {
			dataAll.effectiveDateEn = dateToStrCopy(new Date(value));
			dataAll.userCardEtime = dateToStrCopy(new Date(value))
			dataAll.effectiveDateShow = false;
		}
		const dateCancel = () => {
			dataAll.effectiveDateShow = false;
		}

		// 添加图片 
		const addYyImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.userCardSrc = data.data;
							dataAll.yyImgList.push({ url: baseUrlImg() + dataAll.userCardSrc });
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(err) {
					console.log(err)
				}
			})
		}
 
		const yyImg = () => {
			dataAll.userCardSrc = ref(null);
			dataAll.yyImgList = ref([])
		}
		
		// 证书类型下拉选择
		const selectUserCardType = () => {
			dataAll.userCardTypeList = [
				{ text: "健康证", id: 0 }, 
				{ text: "药师资格证", id: 1 }, 
				{ text: "特设资格证", id: 2 },
			]
			dataAll.defaultIndex = dataAll.userCardTypeList.findIndex((ace) => ace.id == dataAll.userCardType);
			dataAll.userCardTypeShow = true;
		}
		const userCardTypeConfirm = (value) => {
			dataAll.userCardTypeEn = value.text;
			dataAll.userCardType = value.id;
			dataAll.userCardTypeShow = false;
		}
		const userCardTypeCancel = () => {
			dataAll.userCardTypeShow = false;
		}
		
		// 编辑人员页面的
		// const selectUserRegisterTime = () => {
		// 	dataAll.userRegisterShow = true
		// }
		// const userRegisterConfirm = (value) => {
		// 	dataAll.userRegisterEn = dateToStrCopy(new Date(value));
		// 	dataAll.userRegisterDate = dateToStrCopy(new Date(value))
		// 	dataAll.userRegisterTime = value;
		// 	dataAll.userRegisterShow = false;
		// }
		// const userRegisterCancel = () => {
		// 	dataAll.userRegisterShow = false;
		// }
		
		// 执业特设证书类型下拉选择
		const selectUserRelation = () => {
			if (dataAll.userCardType == 1) {
				proxy.$http({
					url: proxy.$http.adornUrl(`/sys/type/getPharmacist`),
					method: 'post',
					data: null,
					headers: {
						token: sessionStorage.getItem("userToken")
					}
				}).then(({
					data
				}) => {
					if (data && data.code === 0) {
						const array = [];
						for (var i = 0; i < data.type.length; i++) {
							array.push({
								text: data.type[i].value,
								id: data.type[i].key
							});
						}
						dataAll.userRelationList = array;
						dataAll.defaultIndex = dataAll.userRelationList.findIndex((ace) => ace.id == dataAll.userRelation);
						dataAll.userRelationShow = true;
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
						});
					} else {
						proxy.$toast.fail(data.msg);
					}
				})
			} else {
				proxy.$http({
					url: proxy.$http.adornUrl(`/sys/type/getEquipmentStatic`),
					method: 'post',
					data: null,
					headers: {
						token: sessionStorage.getItem("userToken")
					}
				}).then(({
					data
				}) => {
					console.log(data);
					if (data && data.code === 0) {
						const array = [];
						for (var i = 0; i < data.type.length; i++) {
							array.push({
								text: data.type[i].facility_task_code + " - " + data.type[i]
									.facility_task_name,
								id: data.type[i].facility_task_id
							});
						}
						dataAll.userRelationList = array;
						dataAll.defaultIndex = dataAll.userRelationList.findIndex((ace) => ace.id == dataAll.userRelation);
						dataAll.userRelationShow = true;
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
						});
					} else {
						proxy.$toast.fail(data.msg);
					}
				})
			}
		}
		const userRelationCancel = () => {
			dataAll.userRelationShow = false;
		}
		const userRelationConfirm = (value) => {
			dataAll.userRelationEn = value.text;
			dataAll.userRelation = value.id;
			dataAll.userRelationShow = false;
		}
		
		// 保存用户接口
		const adduser = () => {
			dataAll.loading = true
			
			if (!dataAll.userCardType && dataAll.userCardType !== 0) {
				proxy.$toast.fail("请选择证件类型！");
				return;
			}
			
			const json = {
				userId: dataAll.userId,
				userSex: dataAll.userSex,
				userCardType: dataAll.userCardType,
				userNumber: dataAll.userNumber,
				userName: dataAll.userName,
				userCardSrc: dataAll.userCardSrc,
				userCardNumber: dataAll.userCardNumber,
				userCardEtime: dataAll.userCardEtime,
				enterpriseQrNumber: dataAll.enterpriseQrNumber
			};

			if (dataAll.userCardType == 0) {
				json["userCardInspect"] = dataAll.userCardInspect;
				json["userCardCompany"] = dataAll.userCardCompany;
			} else if (dataAll.userCardType == 1) {
				json["userCardStime"] = dataAll.userCardStime;
				json["userRegisterNumber"] = dataAll.userRegisterNumber;
				json["userCardAddress"] = dataAll.userCardAddress;
				json["userRelation"] = dataAll.userRelation;
				json["userCardRange"] = dataAll.userCardRange;
			} else if (dataAll.userCardType == 2) {
				json["userCardStime"] = dataAll.userCardStime;
				json["userCardOffice"] = dataAll.userCardOffice;
				json["userRelation"] = dataAll.userRelation;
			}
			
		 
			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/addUpdateUser`),
				method: 'post',
				data: json,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				console.log(data);
				dataAll.loading=false
				if (data && data.code === 0) {
					router.go(-1);
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		
		return {
			...toRefs(dataAll),
			fan,
			selectSex,
			sexConfirm,
			sexCancel,
			selectEffectiveDate,
			dateConfirm,
			dateCancel,
			addYyImg,
			yyImg,
			selectUserCardType,
			userCardTypeConfirm,
			userCardTypeCancel,
			// selectUserRegisterTime,
			// userRegisterConfirm,
			// userRegisterCancel,
			selectUserRelation,
			userRelationCancel,
			userRelationConfirm,
			selectEffectiveDate1,
			dateConfirm1,
			dateCancel1,
			adduser,
		}
	}
}
